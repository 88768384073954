import { Button, Stack, TextField } from '@carvertical/ui';
import { useForm, FormProvider, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { resetPasswordFormSchema, type ResetPasswordFormFields } from 'modules/auth/schemas';
import { FormField } from 'components/common/ui/forms';

type ResetPasswordFormProps = {
  onSubmit: SubmitHandler<ResetPasswordFormFields>;
  loading?: boolean;
};

const ResetPasswordForm = ({ onSubmit, loading }: ResetPasswordFormProps) => {
  const { t } = useTranslation('auth');
  const form = useForm<ResetPasswordFormFields>({
    defaultValues: { password: '', repeatedPassword: '' },
    resolver: zodResolver(resetPasswordFormSchema),
  });
  const { handleSubmit } = form;

  const Field = FormField<ResetPasswordFormFields>;

  return (
    <FormProvider {...form}>
      <Stack as="form" gap={3} crossAxisAlign="stretch" onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <Field name="password">
            <TextField type="password" label={t('changePassword.passwordLabel')} fullWidth />
          </Field>

          <Field name="repeatedPassword">
            <TextField type="password" label={t('changePassword.confirmPasswordLabel')} fullWidth />
          </Field>
        </Stack>

        <Button variant="yellow" fullWidth type="submit" size="l" disabled={loading}>
          {loading ? t('changePassword.pendingMessage') : t('changePassword.action')}
        </Button>
      </Stack>
    </FormProvider>
  );
};

export { ResetPasswordForm };
export type { ResetPasswordFormProps };
