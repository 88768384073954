import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { isSafari } from 'react-device-detect';
import GoogleIcon from 'assets/icons/google.svg';
import AppleIcon from 'modules/auth/assets/icons/apple.svg';
import FacebookIcon from 'modules/auth/assets/icons/facebook.svg';
import { logInWith } from 'services/authApi';
import { getBaseUrl } from 'services/site';
import { addTrailingSlash } from 'utils/router';

type SocialButtonProps = {
  isSignup?: boolean;
};

const APPLE_LOGIN_TIMEOUT = 3_000;

const FACEBOOK = {
  id: 'facebook',
  label: 'Facebook',
  Icon: FacebookIcon,
};
const GOOGLE = {
  id: 'google',
  label: 'Google',
  Icon: GoogleIcon,
};
const APPLE = {
  id: 'apple',
  label: 'Apple',
  Icon: AppleIcon,
};

const CONNECTIONS = [FACEBOOK, GOOGLE, APPLE];

const SocialButtons = ({ isSignup }: SocialButtonProps) => {
  const { t } = useTranslation('auth');
  const [pendingConnection, setPendingConnection] = useState<string | null>(null);

  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted) {
        setPendingConnection(null);
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const doSocialLogin = (provider: string) => {
    setPendingConnection(provider);

    if (isSafari && provider === APPLE.id) {
      setTimeout(() => {
        setPendingConnection(null);
      }, APPLE_LOGIN_TIMEOUT);
    }

    return logInWith(provider, {
      returnTo: `${addTrailingSlash(getBaseUrl())}auth/callback`,
    });
  };

  return (
    <ul className="m-0 flex list-none flex-col items-stretch gap-2 p-0">
      {CONNECTIONS.map(({ Icon, id, label }) => (
        <li key={id}>
          <button
            type="button"
            disabled={!!pendingConnection}
            className={cx(
              'relative inline-flex w-full cursor-pointer items-center justify-center rounded-pill border-none px-6 py-1.5 text-left text-m+ transition-all focus:outline-none focus-visible:ring disabled:opacity-50',
              id === 'apple' && 'bg-black text-white hover:bg-grey-700',
              id === 'facebook' && 'bg-[#1877f2] text-white hover:bg-[#0b5fcc]',
              id === 'google' && 'bg-grey-200 text-black hover:brightness-90',
            )}
            onClick={() => doSocialLogin(id)}
          >
            <Icon className="absolute left-[4.5%] top-[50%] h-3 w-3 -translate-y-1/2" />

            {isSignup
              ? t(
                  pendingConnection === id
                    ? 'common.socialSignUpPendingMessage'
                    : 'common.socialSignUpAction',
                  { service: label },
                )
              : t(
                  pendingConnection === id
                    ? 'common.socialLogInPendingMessage'
                    : 'common.socialLogInAction',
                  { service: label },
                )}
          </button>
        </li>
      ))}
    </ul>
  );
};

export { SocialButtons };
export type { SocialButtonProps };
