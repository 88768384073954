import { Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import styles from './OrSeparator.module.scss';

const OrSeparator = () => {
  const { t } = useTranslation();

  return (
    <Stack className={styles.root} crossAxisAlign="center">
      <Text align="center" className={styles.text}>
        {t('general.orLabel')}
      </Text>
    </Stack>
  );
};

export { OrSeparator };
