import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CheckIconS, ExclamationTriangleFilledIconS, XMarkIconS } from '@carvertical/icons/react';
import styles from './Message.module.scss';

const SUCCESS = 'success';
const ERROR = 'error';
const WARNING = 'warning';

const Message = ({ variant, inline, children }) => (
  <div className={classnames(styles.root, styles[variant], inline && styles.inline)}>
    {variant === SUCCESS && <CheckIconS className={styles.icon} />}
    {variant === ERROR && <XMarkIconS className={styles.icon} />}
    {variant === WARNING && <ExclamationTriangleFilledIconS className={styles.icon} />}
    <div className={styles.text}>{children}</div>
  </div>
);

Message.propTypes = {
  variant: PropTypes.oneOf([SUCCESS, ERROR, WARNING]).isRequired,
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
Message.defaultProps = {
  inline: false,
};

export default Message;
