import { StatusMessage } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import type { ResetPasswordFormFields } from 'modules/auth/schemas';
import { useResetPassword } from '../hooks';
import { AuthPage } from './AuthPage';
import { ResetPasswordForm } from './ResetPasswordForm';

const ResetPasswordPage = () => {
  const { t } = useTranslation('auth');
  const { resetPassword, resetPasswordSuccess, resetPasswordError, resetPasswordLoading } =
    useResetPassword();

  const reset = ({ password }: ResetPasswordFormFields) => resetPassword(password);

  return (
    <AuthPage title={t('changePassword.title')} invalid={!!resetPasswordError}>
      {resetPasswordSuccess ? (
        <StatusMessage variant="success">{t('changePassword.successMessage')}</StatusMessage>
      ) : (
        <ResetPasswordForm onSubmit={reset} loading={resetPasswordLoading} />
      )}
    </AuthPage>
  );
};

export { ResetPasswordPage };
