import PropTypes from 'prop-types';
import cx from 'classnames';
import { convertNewLinesToLineBreaks } from '../../utils/text';
import styles from './Title.module.scss';

const TitleColor = {
  WHITE: 'white',
  BLACK: 'black',
};

const TitleVariant = {
  XL: 'xl',
  L: 'l',
  M: 'm',
  S: 's',
  XS: 'xs',
};

const SubtitleVariant = {
  L: 'l',
  M: 'm',
  S: 's',
};

const { L, M: TitleM, S } = TitleVariant;
const { M: SubtitleM } = SubtitleVariant;
const { BLACK } = TitleColor;

const HEADING_TITLE_CLASS = {
  h1: L,
  h2: L,
  h3: TitleM,
  h4: S,
};

const setProps = (children, otherProps) => ({
  ...otherProps,
  ...(typeof children === 'string'
    ? {
        dangerouslySetInnerHTML: {
          __html: convertNewLinesToLineBreaks(children),
        },
      }
    : {
        children,
      }),
});

const Title = ({
  title,
  as: TitleComponent,
  subtitle,
  className,
  titleProps: otherTitleProps,
  subtitleProps: otherSubtitleProps,
  titleClassName,
  subtitleClassName,
  color,
  titleVariant,
  subtitleVariant,
  centered,
  noSpaceBelow,
  uppercased,
}) => {
  const defaultHeadingClass = HEADING_TITLE_CLASS[TitleComponent] || HEADING_TITLE_CLASS.h2;
  const titleVariantStyle = titleVariant || defaultHeadingClass;

  const titleProps = setProps(title, otherTitleProps);
  const subtitleProps = setProps(subtitle, otherSubtitleProps);

  return (
    <div
      className={cx(
        styles.root,
        centered && styles.centered,
        noSpaceBelow && styles.noSpaceBelow,
        className,
      )}
    >
      <TitleComponent
        {...titleProps}
        className={cx(
          styles.reset,
          styles[`title_${titleVariantStyle}`],
          styles[color],
          titleClassName,
          uppercased && styles.uppercased,
        )}
      />
      {subtitle && (
        <p
          {...subtitleProps}
          className={cx(
            styles.subtitle,
            styles[`subtitle_${subtitleVariant}`],
            styles.reset,
            styles[color],
            subtitleClassName,
          )}
        />
      )}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  as: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  titleProps: PropTypes.shape(),
  subtitleProps: PropTypes.shape(),
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  color: PropTypes.oneOf(Object.values(TitleColor)),
  titleVariant: PropTypes.oneOf(Object.values(TitleVariant)),
  subtitleVariant: PropTypes.oneOf(Object.values(SubtitleVariant)),
  centered: PropTypes.bool,
  noSpaceBelow: PropTypes.bool,
  uppercased: PropTypes.bool,
};

Title.defaultProps = {
  as: 'h1',
  subtitle: undefined,
  className: undefined,
  titleProps: undefined,
  subtitleProps: undefined,
  titleClassName: undefined,
  subtitleClassName: undefined,
  color: BLACK,
  titleVariant: undefined,
  subtitleVariant: SubtitleM,
  centered: false,
  noSpaceBelow: false,
  uppercased: false,
};

export { TitleColor, TitleVariant, SubtitleVariant };
export default Title;
