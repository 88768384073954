import { useForm, FormProvider, type SubmitHandler } from 'react-hook-form';
import { Button, type ButtonVariant, StatusMessage, TextField } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import type { AxiosError } from 'axios';
import { useTranslation } from 'next-i18next';
import { cn } from '@carvertical/utils/styling';
import { MailIconXS } from '@carvertical/icons/react';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { FormField } from 'components/common/ui/forms';
import { getEmailPasswordFormSchema, type EmailPasswordFormFields } from 'modules/auth/schemas';

type EmailPasswordFormProps = {
  actionLabel: string;
  actionPendingLabel: string;
  onSubmit: SubmitHandler<EmailPasswordFormFields>;
  email?: string;
  emailOmitted?: boolean;
  error?: AxiosError | null;
  passwordOmitted?: boolean;
  anyPasswordLengthAllowed?: boolean;
  buttonVariant?: ButtonVariant;
  loading?: boolean;
};

const EmailPasswordForm = ({
  actionLabel,
  actionPendingLabel,
  email,
  emailOmitted,
  error,
  passwordOmitted,
  anyPasswordLengthAllowed,
  onSubmit,
  buttonVariant = 'yellow',
  loading,
}: EmailPasswordFormProps) => {
  const { t } = useTranslation(['common', 'auth']);
  const form = useForm<EmailPasswordFormFields>({
    defaultValues: { email: email || '', password: '' },
    resolver: zodResolver(
      getEmailPasswordFormSchema({ passwordOmitted, anyPasswordLengthAllowed }),
    ),
  });

  const {
    formState: { errors },
    handleSubmit,
  } = form;

  const { errorCode, message } = error?.response?.data || {};
  const emailError = errors.email?.message;
  const unauthorized = message === 'Unauthorized';
  const accountExist = message === 'Conflict';
  const invalidAuthEmail = message === 'Validation of body failed';
  const needsPasswordReset = errorCode === 'reset-password';
  const invalidCaptcha = errorCode === 'invalid-captcha';
  const otherError =
    !unauthorized && !accountExist && !invalidAuthEmail && !needsPasswordReset && !invalidCaptcha;

  const Field = FormField<EmailPasswordFormFields>;

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-stretch gap-3">
        {!emailOmitted && (
          <Field name="email">
            <TextField
              type="email"
              label={t('auth:common.yourEmailLabel')}
              leadingAddon={
                <MailIconXS className={cn('translate-y-px', emailError && 'text-red')} />
              }
              fullWidth
            />
          </Field>
        )}

        {!passwordOmitted && (
          <Field name="password">
            <TextField
              type="password"
              label={t('auth:common.yourPasswordLabel')}
              disabled={loading}
              fullWidth
            />
          </Field>
        )}

        {error && (
          <div className="mb-1.5">
            <StatusMessage variant="error">
              {(unauthorized || needsPasswordReset) && t(ERROR_TRANSLATION_KEYS.unauthorized)}

              {accountExist && t(ERROR_TRANSLATION_KEYS.accountExist)}

              {invalidAuthEmail && t(ERROR_TRANSLATION_KEYS.invalidAuthEmail)}

              {invalidCaptcha && t(ERROR_TRANSLATION_KEYS.invalidCaptcha)}

              {otherError && message}
            </StatusMessage>
          </div>
        )}

        <div className="text-center">
          <Button variant={buttonVariant} fullWidth type="submit" size="l" disabled={loading}>
            {loading ? actionPendingLabel : actionLabel}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export { EmailPasswordForm };
export type { EmailPasswordFormProps };
