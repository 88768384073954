import { Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Link } from 'components/common/Link';
import { OrSeparator } from 'components/common/OrSeparator';
import AuthContainer from 'containers/AuthContainer';
import withContainers from 'hoc/withContainers';
import type { EmailPasswordFormFields } from 'modules/auth/schemas';
import { useSignUp } from '../hooks';
import { AuthPage } from './AuthPage';
import { EmailPasswordForm } from './EmailPasswordForm';
import { SocialButtons } from './SocialButtons';

type SignupPageProps = {
  containers: {
    auth: AuthContainer;
  };
};

const SignupPage = ({ containers: { auth } }: SignupPageProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const { signUp, signUpError, signUpLoading } = useSignUp();
  const [emailFromParams] = useState(
    () => (auth.getTransientAuthParams() as { email: string }).email,
  );

  const submit: SubmitHandler<EmailPasswordFormFields> = ({ email, password }) =>
    signUp({ email, password });

  return (
    <AuthPage title={t('signup.title')} invalid={!!signUpError}>
      <Stack gap={2} crossAxisAlign="stretch">
        <SocialButtons isSignup />

        <OrSeparator />

        <EmailPasswordForm
          email={emailFromParams}
          error={signUpError}
          actionLabel={t('common:auth.signUpAction')}
          actionPendingLabel={t('signup.pendingMessage')}
          onSubmit={submit}
          loading={signUpLoading}
        />
      </Stack>

      <Text align="center" variant="s" textColor="darkSecondary">
        {t('signup.returningUserQuestion')}{' '}
        <Link
          className="underline transition hover:no-underline focus:outline-none focus-visible:ring"
          to="/auth"
        >
          {t('common:auth.logInAction')}
        </Link>
      </Text>
    </AuthPage>
  );
};

const WrappedSignupPage = withContainers({
  auth: AuthContainer,
})(SignupPage);

export { WrappedSignupPage as SignupPage };
