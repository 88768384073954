import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckIcon from '../../assets/icons/check-rounded.svg';
import XIcon from '../../assets/icons/x-rounded.svg';
import colors from '../../styles/variables.module.scss';
import Loading from './Loading';
import styles from './MessageBox.module.scss';

const MessageBox = ({ variant, title, contentClassName, children }) => (
  <div className={classnames(styles.root, variant && styles[variant])}>
    <div className={styles.container}>
      {variant === 'success' && (
        <CheckIcon className={styles.icon} variant="roundSolid" color={colors.success} />
      )}
      {variant === 'failure' && (
        <XIcon className={styles.icon} variant="roundSolid" color={colors.danger} />
      )}
      {variant === 'loading' && <Loading className={styles.loading} />}
      <h1 className={styles.title}>{title}</h1>
      {children && <div className={classnames(styles.content, contentClassName)}>{children}</div>}
    </div>
  </div>
);

MessageBox.propTypes = {
  variant: PropTypes.oneOf(['success', 'failure', 'loading']),
  title: PropTypes.string.isRequired,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
};
MessageBox.defaultProps = {
  variant: undefined,
  contentClassName: undefined,
  children: undefined,
};

export default MessageBox;
