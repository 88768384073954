import PropTypes from 'prop-types';
import EnvelopeCheckCircleIcon from '../../assets/icons/envelope-check-circle.svg';
import Page from './Page';
import Title from './Title';
import styles from './EmailPage.module.scss';

const EmailPage = ({ title, text, children }) => (
  <Page className={styles.root} title={title} breadcrumbs={false}>
    <div className={styles.content}>
      <EnvelopeCheckCircleIcon className={styles.icon} />
      <Title title={title} subtitle={text} centered />
      {children}
    </div>
  </Page>
);

EmailPage.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
};
EmailPage.defaultProps = {
  text: undefined,
  children: undefined,
};

export default EmailPage;
