import { InputOTP, LinkButton } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { useForm, FormProvider, type SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { loginCodeFormSchema, type LoginCodeFormFields } from 'modules/auth/schemas';
import { Link } from 'components/common/Link';

type LoginCodeFormProps = {
  onSubmit: SubmitHandler<LoginCodeFormFields>;
  failed?: boolean;
};

const LoginCodeForm = ({ onSubmit, failed }: LoginCodeFormProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const form = useForm<LoginCodeFormFields>({
    defaultValues: { code: '' },
    resolver: zodResolver(loginCodeFormSchema),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    setValue,
    setFocus,
  } = form;

  useEffect(() => {
    if (failed) {
      setValue('code', '');
      setFocus('code');
    }
  }, [failed, setValue, setFocus]);

  return (
    <FormProvider {...form}>
      <form className="flex flex-col gap-3" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={form.control}
          name="code"
          render={({ field }) => (
            <InputOTP
              aria-label={t('code.codeLabel')}
              align="center"
              length={6}
              status={failed ? 'error' : undefined}
              message={
                failed ? (
                  <Trans
                    i18nKey="code.invalidMessage"
                    components={[
                      <LinkButton key={0} as={Link} href="/auth" size="s" variant="black" />,
                    ]}
                    t={t}
                  />
                ) : (
                  t('code.spamDescription')
                )
              }
              fullWidth
              disabled={isSubmitting}
              onComplete={(code) => {
                field.onChange(code);
                handleSubmit(onSubmit)();
              }}
              {...field}
            />
          )}
        />
      </form>
    </FormProvider>
  );
};

export { LoginCodeForm };
export type { LoginCodeFormProps };
