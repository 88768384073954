import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import Message from 'components/common/Message';
import AuthContainer from 'containers/AuthContainer';
import withContainers from 'hoc/withContainers';
import { useForgotPassword } from '../hooks';
import type { EmailPasswordFormFields } from '../schemas';
import { AuthPage } from './AuthPage';
import { EmailPasswordForm } from './EmailPasswordForm';

type ForgotPasswordPageProps = {
  containers: {
    auth: AuthContainer;
  };
};

const ForgotPasswordPage = ({ containers: { auth } }: ForgotPasswordPageProps) => {
  const { t } = useTranslation('auth');
  const { forgotPasswordSuccess, forgotPassword, forgotPasswordError, forgotPasswordLoading } =
    useForgotPassword();
  const [emailFromParams] = useState(
    () => (auth.getTransientAuthParams() as { email: string }).email,
  );

  const submit: SubmitHandler<EmailPasswordFormFields> = ({ email }) => forgotPassword({ email });

  return (
    <AuthPage
      title={t('forgotPassword.title')}
      description={t('forgotPassword.description')}
      invalid={!!forgotPasswordError}
      backHref="/auth"
    >
      {forgotPasswordSuccess ? (
        <Message variant="success">{t('forgotPassword.successMessage')}</Message>
      ) : (
        <EmailPasswordForm
          email={emailFromParams}
          error={forgotPasswordError}
          actionLabel={t('forgotPassword.action')}
          actionPendingLabel={t('forgotPassword.pendingMessage')}
          passwordOmitted
          onSubmit={submit}
          loading={forgotPasswordLoading}
        />
      )}
    </AuthPage>
  );
};

const WrappedForgotPasswordPage = withContainers({
  auth: AuthContainer,
})(ForgotPasswordPage);

export { WrappedForgotPasswordPage as ForgotPasswordPage };
