import { Button, Checkbox } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEmailSubscription } from 'modules/subscription/hooks';
import { Link } from 'components/common/Link';
import MessageBox from 'components/common/MessageBox';
import Page from 'components/common/Page';
import Redirect from 'components/common/Redirect';
import AuthContainer from 'containers/AuthContainer';
import UserContainer from 'containers/UserContainer';
import { useRouteData } from 'context/RouteDataProvider';
import withContainers from 'hoc/withContainers';
import { FormField } from 'components/common/ui/forms';
import { acceptTerms } from 'services/authApi';
import { newUserFormSchema, type NewUserFormFields } from '../schemas';

type NewUserPageProps = {
  containers: {
    auth: AuthContainer;
    user: UserContainer;
  };
};

const NewUserPage = ({ containers: { auth, user } }: NewUserPageProps) => {
  const { t } = useTranslation();

  const {
    locale,
    market,
    pages: { terms: termsPage, privacy: privacyPage },
  } = useRouteData();

  const [redirectTo, setRedirectTo] = useState('');

  const { subscribe } = useEmailSubscription();

  const form = useForm<NewUserFormFields>({
    defaultValues: { termsAccepted: undefined, newsletterAccepted: false },
    resolver: zodResolver(newUserFormSchema),
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = form;

  const { redirectTo: redirectToFromState = '/' } = auth.state;
  // @ts-expect-error TS(2339)
  const { email } = user.state;

  const title = t('newUserPage.title');

  const submit = async ({ newsletterAccepted }: NewUserFormFields) => {
    await acceptTerms();

    if (newsletterAccepted) {
      subscribe({ email, locale, market: market.id, source: 'New User Page' });
    }

    setRedirectTo(redirectToFromState);
  };

  const Field = FormField<NewUserFormFields>;

  return (
    <Page title={title} breadcrumbs={false}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(submit)}>
          <MessageBox title={title}>
            <Trans parent="p" className="m-0" i18nKey="newUserPage.text" t={t}>
              To use our services, please review and agree to our{' '}
              <Link to={termsPage.path} target="_blank">
                terms and conditions
              </Link>
              .
            </Trans>

            <div className="mb-3 mr-0 mt-1 text-left">
              <Field name="termsAccepted">
                <Checkbox
                  label={
                    <Trans parent="span" i18nKey="newUserPage.termsPrivacyLabel" t={t}>
                      I agree to{' '}
                      <Link to={termsPage.path} target="_blank">
                        terms and conditions
                      </Link>{' '}
                      and{' '}
                      <Link to={privacyPage.path} target="_blank">
                        privacy policy
                      </Link>
                    </Trans>
                  }
                />
              </Field>
            </div>

            {email && (
              <div className="mb-3 mr-0 mt-1 text-left">
                <Field name="newsletterAccepted">
                  <Checkbox label={t('newUserPage.newsletterCheckboxLabel')} />
                </Field>
              </div>
            )}

            <Button variant="yellow" type="submit" disabled={isSubmitting}>
              {isSubmitting ? `${t('general.pleaseWaitLabel')}…` : t('general.continueAction')}
            </Button>

            {redirectTo && <Redirect to={redirectTo} />}
          </MessageBox>
        </form>
      </FormProvider>
    </Page>
  );
};

const WrappedNewUserPage = withContainers({
  auth: AuthContainer,
  user: UserContainer,
})(NewUserPage);

export { WrappedNewUserPage as NewUserPage };
