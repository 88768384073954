import { useTranslation } from 'next-i18next';
import EmailPage from 'components/common/EmailPage';

const ActivationPage = () => {
  const { t } = useTranslation();

  return (
    <EmailPage title={t('userVerificationPage.title')} text={t('userVerificationPage.text')} />
  );
};

export { ActivationPage };
