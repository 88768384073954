import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { MessagePage } from 'components/common/MessagePage';
import Redirect from 'components/common/Redirect';
import AuthContainer from 'containers/AuthContainer';
import UserContainer from 'containers/UserContainer';
import withContainers from 'hoc/withContainers';
import { exchangeToken } from 'services/authApi';

type CallbackPageProps = {
  containers: {
    auth: AuthContainer;
    user: UserContainer;
  };
};

const CallbackPage = ({ containers: { auth, user } }: CallbackPageProps) => {
  const { t } = useTranslation();

  const [accessToken] = useState(
    () => (auth.getTransientAuthParams() as { accessToken: string }).accessToken,
  );
  const [clientError, setClientError] = useState();
  const { query, isReady } = useRouter();
  const { code, error: serverError, state } = query;
  const pending = accessToken || !isReady || (state && code);

  const { redirectTo } = auth.state;
  // @ts-expect-error TS(2339)
  const { id, termsAcceptedAt } = user.state;

  useEffect(() => {
    (async () => {
      if (serverError || !isReady) {
        return;
      }
      try {
        await auth.authenticate(accessToken || (await exchangeToken({ state, code })));
      } catch (err) {
        // @ts-expect-error TS(2339)
        setClientError(err.message);
      }
    })();
  }, [accessToken, state, code, serverError, auth, isReady]);

  if (!pending && !serverError) {
    return <Redirect to="/" />;
  }

  const error = clientError || serverError;

  return (
    <>
      {id && (
        <>{termsAcceptedAt ? <Redirect to={redirectTo || '/'} /> : <Redirect to="/auth/new" />}</>
      )}

      {!error ? (
        <MessagePage>
          <p>{t('auth.pendingMessage')}</p>
        </MessagePage>
      ) : (
        <MessagePage error>
          <Trans parent="p" i18nKey="auth.errorMessage" t={t}>
            Could not authenticate due to: <strong>{error}</strong>
          </Trans>
        </MessagePage>
      )}
    </>
  );
};

const WrappedCallbackPage = withContainers({
  auth: AuthContainer,
  user: UserContainer,
})(CallbackPage);

export { WrappedCallbackPage as CallbackPage };
