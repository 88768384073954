import { useState } from 'react';
import { Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import type { AxiosError } from 'axios';
import { flow } from 'lodash';
import type { Router } from 'next/router';
import withRouter from 'hoc/withRouter';
import withContainers from 'hoc/withContainers';
import AuthContainer from 'containers/AuthContainer';
import { OrSeparator } from 'components/common/OrSeparator';
import { Link } from 'components/common/Link';
import { logIn } from 'services/authApi';
import type { EmailPasswordFormFields } from 'modules/auth/schemas';
import { useRecaptcha } from '../hooks';
import { storeAccessToken } from '../utils';
import { AuthPage } from './AuthPage';
import { SocialButtons } from './SocialButtons';
import { EmailPasswordForm } from './EmailPasswordForm';

type LoginPageProps = {
  containers: {
    auth: AuthContainer;
  };
  router: { history: Router };
};

const LoginPage = ({ containers: { auth }, router: { history } }: LoginPageProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const [emailFromParams] = useState(
    () => (auth.getTransientAuthParams() as { email: string }).email,
  );
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const { getCaptchaResponse } = useRecaptcha();

  // TODO: Handle async/wait with react-query
  const doLogin = async ({ email, password }: EmailPasswordFormFields) => {
    setError(undefined);
    try {
      auth.setTransientAuthParams({
        accessToken: await logIn(email, password, {
          captchaResponse: await getCaptchaResponse(),
        }),
      });
      await history.push('/auth/callback');
    } catch (err) {
      // @ts-expect-error TS(2339)
      const { errorCode, accessToken } = err.response?.data || {};
      if (errorCode === 'code-required' && accessToken) {
        storeAccessToken(accessToken);
        await history.push('/auth/code');
      } else if (errorCode === 'email-unverified') {
        await history.push('/auth/activate');
      } else {
        auth.setTransientAuthParams({ email });
        // @ts-expect-error TS(2345)
        setError(err);
      }
    }
  };

  return (
    <AuthPage title={t('login.title')} invalid={!!error}>
      <Stack gap={2} crossAxisAlign="stretch">
        <SocialButtons />

        <OrSeparator />

        <EmailPasswordForm
          email={emailFromParams}
          error={error}
          actionLabel={t('common:auth.logInAction')}
          actionPendingLabel={t('login.pendingMessage')}
          onSubmit={doLogin}
          anyPasswordLengthAllowed
        />
      </Stack>

      <Stack gap={3} crossAxisAlign="center">
        <Text variant="s" as="span" textColor="darkSecondary">
          <Link
            className="underline transition hover:no-underline focus:outline-none focus-visible:ring"
            to="/auth/forgot"
          >
            {t('login.forgotPasswordAction')}
          </Link>
        </Text>

        <Text variant="s" textColor="darkSecondary">
          {t('login.newUserQuestion')}{' '}
          <Link
            className="underline transition hover:no-underline focus:outline-none focus-visible:ring"
            to="/auth/signup"
          >
            {t('login.createAccountAction')}
          </Link>
        </Text>
      </Stack>
    </AuthPage>
  );
};

const WrappedLoginPage = flow(withContainers({ auth: AuthContainer }), withRouter)(LoginPage);

export { WrappedLoginPage as LoginPage };
